import React from "react"
import Layout from "../../components/layout"
import Hero from "../../components/hero"

export default () => (
  <>
    <Layout>
      <Hero />

        {/* Style Guide Section
        ================================================== */}
        <section id="styles"
          style= {{
            padding: '90px 40px 72px',
            background: '#fff'
          }}
        >
          <div className="row section-head">
            <h1>CONDICIONES GENERALES DE USO</h1>
          </div>

           <div className="row" >


                 <h5>Artículo 1: OBJETIVO</h5>

                 
                  <div>Las presentes Condiciones Generales de Uso constituyen un marco legal para el uso del sitio web de <b>Juliette Chastel - Traductrice</b> y sus servicios. El acceso al sitio implica la aceptación de estas condiciones de uso.</div>
                

                 <h5>Artículo 2: AVISOS LEGALES</h5>
                 <ul >
                <li>Juliette Chastel - Traductrice</li>
                <li>Sede social: 115 rue de Reuilly, 75012 PARÍS</li>
                <li>Correo: jchastel.traduction@gmail.com Número de teléfono: +33173710384</li>
                <li>Régimen francés de microempresas</li>
                <li>SIRET: 904 556 156 00019</li>
                <li>Exento de inscripción en el Registro Mercantil francés (Registre du commerce et des sociétés, RCS)</li>
                <li>Representante legal: Juliette Chastel</li>
                <li>Alojamiento: Netlify, Inc. Sede social : 2325 3RD STREET,SUITE 215, SAN FRANCISCO, CA 94107 (USA).</li>
                <li>Creación del sitio web: Pierrick Houede.</li>
                </ul>
                <h5>Artículo 3: ACCESO A LOS SERVICIOS</h5>
                
                  <div>El sitio web de <b>Juliette Chastel – Traductrice</b> permite acceder a los siguientes servicios:</div>
                
                <ul >
                <li>Servicios de traducción y revisión</li>
                <li>Servicios de transcripción y subtitulado</li>
                <li>Servicios de terminología y editoriales</li>
                </ul>
                

                <h5>Artículo 4: RECOGIDA DE DATOS</h5>

                 
                  <div>La dirección electrónica (e-mail) proporcionada por el Usuario podrá ser utilizada por el sitio web de <b>Juliette Chastel – Traductrice</b> para comunicarle diversas informaciones.
                     </div>
                     <div><b>Juliette Chastel – Traductrice</b> garantiza el respeto de la vida privada del Usuario, de conformidad con la ley francesa n°78-17 del 6 de enero de 1978 relativa a la informática, los archivos y las libertades.
                     </div>
                     <div>De acuerdo con los artículos 39 y 40 de la ley francesa del 6 de enero de 1978, el Usuario tiene derecho a acceder, rectificar, eliminar y oponerse a sus datos personales.
                     </div>
                     <div>El Usuario puede ejercer este derecho enviando un correo electrónico a jchastel.traduction@gmail.com 
                     </div>
                

                <h5>Artículo 5: PROPIEDAD INTELECTUAL</h5>

                 
                  <div>El logotipo y los contenidos del sitio web de <b>Juliette Chastel - Traductrice</b> están protegidos por el Código de la Propiedad Intelectual y por los derechos de autor.
                     </div>
                <div>La reproducción y copia de los contenidos por parte del Usuario requiere la autorización previa del sitio. En este caso, está prohibido cualquier uso con fines comerciales o publicitarios.
                    </div>
                
            </div>

           

        </section> {/* Style Guide Section End*/}

    </Layout>
  </>
)